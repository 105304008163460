/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import {useFormik} from 'formik';
import moment from 'moment';
import {useState} from 'react';
import {loginLogApi} from 'src/app/apis/system/login-log';
import * as Yup from 'yup';
import {toAbsoluteUrl} from '../../../../_metronic/helpers';
import {getCurrentPermissions, getUserByToken, login} from '../core/_requests';
import {useAuth} from '../core/Auth';
import {authApi} from 'src/app/apis';

const loginSchema = Yup.object().shape({
  userName: Yup.string().min(3, 'Tối thiểu 3 kí tự').max(50, 'Tối đa 50 kí tự').required('Tài khoản là bắt buộc'),
  password: Yup.string().min(3, 'Tối thiểu 3 kí tự').max(50, 'Tối đa 50 kí tự').required('Mật khẩu là bắt buộc'),
});

const isDevlopment = process.env.NODE_ENV === 'development';

const initialValues = {
  userName: isDevlopment ? 'root.admin' : '',
  password: isDevlopment ? 'Tandan@123' : '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const {saveAuth, setCurrentUser, setCurrentPermissions} = useAuth();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true);
      try {
        const {data: auth} = await login(values.userName, values.password);
        saveAuth(auth);
        const {data: user} = await getUserByToken(auth.token);
        const {data: permissions} = await getCurrentPermissions();
        console.log(user);
        setCurrentPermissions(permissions);
        setCurrentUser(user);
        const {data: log} = await loginLogApi.addAsync({
          userId: user.id,
          loginTime: moment(),
        });
        console.log(log);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          setStatus('Thông tin đăng nhập không chính xác!');
        } else {
          setStatus('Đã có lỗi xảy ra, vui lòng kiểm tra lại!');
        }

        saveAuth(undefined);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <div className='w-100'>
          <img src={toAbsoluteUrl('/media/misc/QBTV_logo.png')} style={{width: '144px'}} alt='logo' />
        </div>
        <div className='w-100 mb-9'>
          <img src={toAbsoluteUrl('/media/misc/qbtv_text.png')} style={{height: '40px'}} alt='logo' />
        </div>
        {/* <h1 className='text-dark fw-bolder mb-9'>Đài phát thanh và truyền hình Quảng Bình</h1> */}
        <h2 className='text-dark fw-bolder'>Đăng nhập</h2>
        <div className='text-gray-500 fw-semibold fs-6'></div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Tài khoản</label>
        <input
          placeholder='Tài khoản'
          {...formik.getFieldProps('userName')}
          className={clsx('form-control bg-transparent', {'is-invalid': formik.touched.userName && formik.errors.userName})}
          type='text'
          name='userName'
          autoComplete='off'
        />
        {formik.touched.userName && formik.errors.userName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.userName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Mật khẩu</label>
        <input
          type='password'
          autoComplete='off'
          placeholder='Mật khẩu'
          {...formik.getFieldProps('password')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.password && formik.errors.password,
          })}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary' disabled={formik.isSubmitting || !formik.isValid}>
          {!loading && <span className='indicator-label'>Đăng nhập</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  );
}
