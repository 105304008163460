import {useState} from 'react';
import {useIntl} from 'react-intl';
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {PageTitle} from '../../../_metronic/layout/core';
import {CardsWidget20} from '../../../_metronic/partials/widgets';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {useEffect} from 'react';
import {requestGET} from 'src/utils/baseAPI';
import {TeamOutlined, UserOutlined} from '@ant-design/icons';
import {Card, Col, Row, Statistic} from 'antd';
import {dashboardApi} from 'src/app/apis/dashboard';
import {useAuth} from 'src/app/modules/auth';
import {checkRole} from 'src/utils/utils';
import {Link} from 'react-router-dom';

const DashboardWrapper = () => {
  const {currentPermissions} = useAuth();

  const [optionChart, setOption] = useState([]);
  const [notifys, setNotifys] = useState([]);
  const [widgets, setWidgets] = useState();
  const [dataThongKe, setDataThongKe] = useState({
    chart: {
      height: '300px',
    },
    title: {
      text: '',
      align: 'left',
    },
    yAxis: {
      title: {
        text: 'Đề tài',
      },
    },

    xAxis: {
      categories: [
        'Tin',
        'Phóng sự',
        'Bản tin',
        'Trả lời khán giả',
        'Truyền hình trực tiếp',
        'Tin nói',
        'Tin miền núi',
        'Tin đồng bằng',
        'Bản tin 10 phút',
        'Thể thao',
        'Tin trong tỉnh',
      ],
    },

    legend: {
      layout: 'vertical',
      align: 'center',
    },

    series: [
      {
        name: 'Đề tài',
        data: [43934, 48656, 65165, 81827, 112143, 142383, 171533, 165174, 155157, 161454, 154610],
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  });
  const [dataThietBi, setDataThietBi] = useState({
    chart: {
      type: 'column',
      height: '300px',
    },
    title: {
      text: '',
      align: 'left',
    },
    xAxis: {
      categories: ['Xe', 'Phòng họp', 'Phòng dựng', 'Trường quay', 'Camera'],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Thiết bị',
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      align: 'left',
      x: 70,
      verticalAlign: 'top',
      y: 70,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Đang được sử dụng',
        data: [3, 5, 1, 13, 12],
      },
      {
        name: 'Chưa sử dụng',
        data: [14, 8, 8, 12, 5],
      },
    ],
  });
  const [dataDGCB, setDataDGCB] = useState({
    chart: {
      type: 'pie',
      height: '300px',
    },
    title: {
      text: '',
      align: 'left',
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          connectorColor: 'silver',
        },
      },
    },
    series: [
      {
        name: '',
        data: [
          {name: 'Hoàn thành xuất sắc nhiệm vụ', y: 80},
          {name: 'Hoàn thành tốt nhiệm vụ', y: 25},
          {name: 'Hoàn thành nhiệm vụ', y: 15},
          {name: 'Không hoàn thành nhiệm vụ', y: 2},
        ],
      },
    ],
  });
  const [nhacViecDeTai, setNhacViecDeTai] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      const res = await requestGET(`api/v1/dashboard`);
      if (res) {
        setOption(res);
        setDataThietBi({
          chart: {
            type: 'column',
            height: '300px',
          },
          title: {
            text: '',
            align: 'left',
          },
          xAxis: {
            categories: res.chartThietBi.categories,
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Thiết bị',
            },
            stackLabels: {
              enabled: true,
            },
          },
          legend: {
            align: 'left',
            x: 70,
            verticalAlign: 'top',
            y: 70,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
          },
          tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: res.chartThietBi.chartSeries,
        });
        setDataDGCB({
          chart: {
            type: 'pie',
            height: '300px',
          },
          title: {
            text: '',
            align: 'left',
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                connectorColor: 'silver',
              },
            },
          },
          series: res.chartDanhGiaCanBo.chartSeries,
        });
      }
    };
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await requestGET(`api/v1/dashboard/notifycation`);
      if (Array.isArray(res)) {
        setNotifys(res);
      }
    };
    fetchData();
    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await dashboardApi.getNhacViecDeTai();

      if (res && res.data) {
        setNhacViecDeTai(res.data);
      }
    };

    fetchData();
  }, []);

  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/* begin::Row */}
      <Card style={{marginBottom: '10px'}} className={'card card-flush undefined'}>
        <div className='row gy-5 mb-5 mb-xl-10'>
          <div className='col'>
            <a href='#!'>
              <Statistic title='Đối tượng hưởng thù lao' value={optionChart.countDoiTuongHuongThuLao} prefix={<TeamOutlined />} />
            </a>
          </div>
          <div className='col'>
            <Statistic title='Đối tượng hưởng nhuận bút' value={optionChart.countDoiTuongHuongNhuanBut} prefix={<UserOutlined />} />
          </div>
          <div className='col'>
            <Statistic title='Tổng thù lao đơn vị' value={95000000} prefix={'đ'} />
          </div>
          <div className='col'>
            <Statistic title='Tổng nhuận bút đơn vị' value={140000000} prefix={'đ'} />
          </div>
        </div>
      </Card>
      <Row gutter={10} style={{marginBottom: '10px'}}>
        <Col span={8}>
          <Card title='Danh sách công việc' style={{height: '400px', overflow: 'scroll'}} className={'card card-flush undefined'}>
            {notifys?.map((item) => (
              <div>
                <div className='d-flex flex-stack'>
                  <div className='text-gray-700 fw-semibold fs-6 me-2'>{item.title}</div>
                  <div className='d-flex align-items-senter'>
                    <span className='badge badge-circle badge-danger'>{item.value}</span>
                  </div>
                </div>
                <div className='separator separator-dashed my-3'></div>
              </div>
            ))}
            {checkRole(currentPermissions, ['Permissions.DeTai.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-tai-cho-thong-qua'>
                  <RemindItem text='Đề tài chờ thông qua' number={nhacViecDeTai.deTaiChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.DeTai.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-tai-cho-duyet'>
                  <RemindItem text='Đề tài chờ duyệt' number={nhacViecDeTai.deTaiChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.DeCuong.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-cuong-cho-thong-qua'>
                  <RemindItem text='Đề cương chờ thông qua' number={nhacViecDeTai.deCuongChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.DeCuong.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/de-cuong-cho-duyet'>
                  <RemindItem text='Đề cương chờ duyệt' number={nhacViecDeTai.deCuongChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.KichBan.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/kich-ban-cho-thong-qua'>
                  <RemindItem text='Kịch bản chờ thông qua' number={nhacViecDeTai.kichBanChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.KichBan.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/kich-ban-cho-duyet'>
                  <RemindItem text='Kịch bản chờ duyệt' number={nhacViecDeTai.kichBanChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/khsx-cho-thong-qua'>
                  <RemindItem text='Kế hoạch sản xuất chờ thông qua' number={nhacViecDeTai.keHoachSanXuatChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/khsx-cho-duyet'>
                  <RemindItem text='Kế hoạch sản xuất chờ duyệt' number={nhacViecDeTai.keHoachSanXuatChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.TacPham.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/thong-qua-tac-pham'>
                  <RemindItem text='Tác phẩm chờ thông qua' number={nhacViecDeTai.tacPhamChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.TacPham.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/duyet-tac-pham'>
                  <RemindItem text='Tác phẩm chờ duyệt' number={nhacViecDeTai.tacPhamChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}

            {checkRole(currentPermissions, ['Permissions.XepLoaiTacPham.ThongQua']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/thong-qua-xep-loai'>
                  <RemindItem text='Xếp loại tác phẩm chờ thông qua' number={nhacViecDeTai.xepLoaiTacPhamChoThongQua} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
            {checkRole(currentPermissions, ['Permissions.XepLoaiTacPham.Duyet']) && (
              <>
                <Link to='/manage/chuong-trinh-de-tai/duyet-xep-loai'>
                  <RemindItem text='Xếp loại tác phẩm chờ duyệt' number={nhacViecDeTai.xepLoaiTacPhamChoDuyet} />
                </Link>
                <div className='separator separator-dashed my-3'></div>
              </>
            )}
          </Card>
        </Col>
        <Col span={16}>
          <Card title='Thống kê đề tài theo thể loại' style={{height: '400px'}} className={'card card-flush undefined'}>
            <HighchartsReact highcharts={Highcharts} options={dataThongKe} oneToOne={true} />
          </Card>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Card title='Tỷ lệ sử dụng thiết bị' style={{height: '400px'}} className={'card card-flush undefined'}>
            <HighchartsReact highcharts={Highcharts} options={dataThietBi} oneToOne={true} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title='Đánh giá cán bộ trong kỳ' style={{height: '400px'}} className={'card card-flush undefined'}>
            <HighchartsReact highcharts={Highcharts} options={dataDGCB} oneToOne={true} />
          </Card>
        </Col>
      </Row>
      <div className='row gy-5 mb-5 mb-xl-10'>
        {/* begin::Col */}

        {widgets?.map((item) => (
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3'>
            <CardsWidget20
              key={1}
              className='h-md-5'
              description={item.headText}
              color={item.backGround}
              head_value={item.headValue}
              bot_text={item.botText}
              bot_value={item.botValue}
              icon={item.icon}
              percentage={item.percentage}
              img={toAbsoluteUrl('/media/patterns/vector-12.png')}
            />
          </div>
        ))}

        {/* <div className='col-12 pt-1'>
          <div className={`card mt-5`}>
            <div className='card-header ribbon ribbon-top ribbon-vertical px-3'>
              <div className='card-title text-primary'>
                <span className='fa fa-chart-bar me-3 text-primary'></span> Biểu đồ thống kê Đài truyền hình
              </div>
            </div>
            <div className='card-body pb-0 px-4'>
            </div>
          </div>
        </div>*/}
      </div>
    </>
  );
};

const RemindItem = ({text, number}) => {
  return (
    <div class='d-flex flex-stack'>
      <div class='text-gray-700 fw-semibold fs-6 me-2'>{text}</div>
      <div class='d-flex align-items-senter'>
        <span className='badge badge-circle badge-danger'>{number}</span>
      </div>
    </div>
  );
};

export {DashboardWrapper};
