import React from 'react';
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub';
import {SidebarMenuItem} from './SidebarMenuItem';
import {useAuth} from 'src/app/modules/auth';
import {isFake} from 'src/utils/utils';

const SidebarMenuMain = () => {
  const {currentPermissions} = useAuth();

  const checkRole = (roles, role) => {
    if (!roles) {
      return false;
    }
    return roles.some((v) => role.includes(v));
  };

  return (
    <>
      <SidebarMenuItem to='dashboard' icon='/media/icons/duotune/art/art002.svg' title={'Trang chủ'} fontIcon='bi-app-indicator' />

      {/* Danh mục */}
      {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
        <SidebarMenuItemWithSub to='danh-muc' title='Danh mục' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen025.svg'>
          {/* <SidebarMenuItem to='/danh-muc/loai-hinh-tai-lieu' title='Loại hình tài liệu' hasBullet={true} /> */}

          {/* <SidebarMenuItem to='/danh-muc/nhom-tai-lieu' title='Nhóm tài liệu' hasBullet={true} /> */}

          {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
            <>
              <SidebarMenuItem to='danh-muc/nhom-danh-muc' title='Nhóm danh mục' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/danh-muc-chung' title='Danh mục chung' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/cong-viec' title='Công việc' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/loai-hinh-bao-chi' title='Loại hình báo chí' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/linh-vuc' title='Lĩnh vực' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/chuong-trinh-de-an-du-an' title='Chương trình, đề án, dự án' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/nguon-khai-thac' title='Nguồn khai thác' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/xep-loai-tac-pham' title='Xếp loại tác phẩm' hasBullet={true} />
              {/* <SidebarMenuItem to='danh-muc/chuc-vu' title='Chức vụ' hasBullet={true} /> */}
              <SidebarMenuItem to='danh-muc/ty-le-khai-thac' title='Tỷ lệ khai thác' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/khoan-cong-viec' title='Khoán công việc' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/cong-thuc-tinh' title='Công thức tính' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/he-so-khuyen-khich' title='Hệ số khuyến khích' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/chuyen-muc-chuyen-de' title='Chuyên mục chuyên đề' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/so-chuong-trinh' title='Sổ chương trình' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/tinh-thanh' title='Tỉnh thành' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/huyen-thi' title='Huyện thị' hasBullet={true} />
              <SidebarMenuItem to='danh-muc/xa-phuong' title='Xã phường' hasBullet={true} />
              {/* <SidebarMenuItem to='danh-muc/thongbao-tintuc' title='Thông báo, tin tức' hasBullet={true} /> */}
              <SidebarMenuItem to='danh-muc/the-loai' title='Thể loại' hasBullet={true} />
            </>
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Chương trình đề tài */}
      {checkRole(currentPermissions, ['Permissions.DeTai.Search', 'Permissions.TacPham.Search']) && (
        <SidebarMenuItemWithSub
          to='chuong-trinh-de-tai'
          title='Chương trình đề tài'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/social/soc009.svg'
        >
          {checkRole(currentPermissions, ['Permissions.DeTai.Search', 'Permissions.DeTai.ThongQua', 'Permissions.DeTai.Duyet']) && (
            <SidebarMenuItemWithSub title='Ý tưởng kịch bản' fontIcon='bi-sticky' icon='/media/icons/duotune/social/soc009.svg'>
              {checkRole(currentPermissions, ['Permissions.DeTai.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/phieu-dang-ky-de-tai' title='Đăng ký' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.DeTai.ThongQua']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/de-tai-cho-thong-qua' title='Thông qua' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.DeTai.Duyet']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/de-tai-cho-duyet' title='Phê duyệt' hasBullet={true} />
              )}
            </SidebarMenuItemWithSub>
          )}

          {checkRole(currentPermissions, ['Permissions.DeCuong.Search', 'Permissions.DeCuong.ThongQua', 'Permissions.DeCuong.Duyet']) && (
            <SidebarMenuItemWithSub title='Đề cương kịch bản' fontIcon='bi-sticky' icon='/media/icons/duotune/social/soc005.svg'>
              {checkRole(currentPermissions, ['Permissions.DeCuong.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/de-cuong-kich-ban' title='Xây dựng' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.DeCuong.ThongQua']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/de-cuong-cho-thong-qua' title='Thông qua' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.DeCuong.Duyet']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/de-cuong-cho-duyet' title='Phê duyệt' hasBullet={true} />
              )}
            </SidebarMenuItemWithSub>
          )}

          {checkRole(currentPermissions, ['Permissions.KichBan.Search', 'Permissions.KichBan.ThongQua', 'Permissions.KichBan.Duyet']) && (
            <SidebarMenuItemWithSub title='Kịch bản' fontIcon='bi-sticky' icon='/media/icons/duotune/social/soc008.svg'>
              {checkRole(currentPermissions, ['Permissions.KichBan.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/kich-ban-de-tai' title='Xây dựng' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.KichBan.ThongQua']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/kich-ban-cho-thong-qua' title='Thông qua' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.KichBan.Duyet']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/kich-ban-cho-duyet' title='Phê duyệt' hasBullet={true} />
              )}
            </SidebarMenuItemWithSub>
          )}

          {checkRole(currentPermissions, [
            'Permissions.KeHoachSanXuat.Search',
            'Permissions.KeHoachSanXuat.ThongQua',
            'Permissions.KeHoachSanXuat.Duyet',
          ]) && (
            <SidebarMenuItemWithSub title='Kế hoạch sản xuất' fontIcon='bi-sticky' icon='/media/icons/duotune/social/soc003.svg'>
              {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/khsx-de-tai' title='Xây dựng' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.ThongQua']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/khsx-cho-thong-qua' title='Thông qua' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.Duyet']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/khsx-cho-duyet' title='Phê duyệt' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/viet-loi-binh' title='Viết lời bình' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.KeHoachSanXuat.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/kich-ban-dung' title='Viết kịch bản dựng' hasBullet={true} />
              )}
            </SidebarMenuItemWithSub>
          )}

          {checkRole(currentPermissions, ['Permissions.TacPham.Search', 'Permissions.TacPham.ThongQua', 'Permissions.TacPham.Duyet']) && (
            <SidebarMenuItemWithSub title='Tác phẩm' fontIcon='bi-sticky' icon='/media/icons/duotune/finance/fin008.svg'>
              {checkRole(currentPermissions, ['Permissions.TacPham.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/tac-pham' title='Xây dựng' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.TacPham.ThongQua']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/thong-qua-tac-pham' title='Thông qua' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.TacPham.Duyet']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/duyet-tac-pham' title='Duyệt phát sóng' hasBullet={true} />
              )}

              {checkRole(currentPermissions, ['Permissions.TacPham.Duyet']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/nhap-ti-le-khai-thac' title='Nhập tỉ lệ khai thác' hasBullet={true} />
              )}
            </SidebarMenuItemWithSub>
          )}

          {checkRole(currentPermissions, [
            'Permissions.XepLoaiTacPham.Search',
            'Permissions.XepLoaiTacPham.ThongQua',
            'Permissions.XepLoaiTacPham.Duyet',
          ]) && (
            <SidebarMenuItemWithSub title='Xếp loại tác phẩm' fontIcon='bi-sticky' icon='/media/icons/duotune/finance/fin003.svg'>
              {checkRole(currentPermissions, ['Permissions.XepLoaiTacPham.Search']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/dang-ky-xep-loai' title='Đăng ký' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.XepLoaiTacPham.ThongQua']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/thong-qua-xep-loai' title='Thông qua' hasBullet={true} />
              )}
              {checkRole(currentPermissions, ['Permissions.XepLoaiTacPham.Duyet']) && (
                <SidebarMenuItem to='chuong-trinh-de-tai/duyet-xep-loai' title='Duyệt' hasBullet={true} />
              )}
            </SidebarMenuItemWithSub>
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Nhuận bút thù lao */}
      {checkRole(currentPermissions, [
        'Permissions.ThuLaoNhuanBut.Manage',
        'Permissions.ThuLao.Manage',
        'Permissions.NhuanBut.Manage',
        'Permissions.ThuLaoCaNhan.Manage',
        'Permissions.NhuanButCaNhan.Manage',
      ]) && (
        <SidebarMenuItemWithSub
          to='thu-lao-nhuan-but'
          title='Nhuận bút - Thù lao'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/finance/fin006.svg'
        >
          {checkRole(currentPermissions, ['Permissions.NhuanBut.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/dinh-muc-nhan-vien' title='Định mức nhân viên' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.NhuanBut.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/dinh-muc-bo-phan' title='Định mức bộ phận' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.ThuLao.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/doi-tuong-huong-thu-lao' title='Đối tượng hưởng thù lao' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.BangKeCongViec.Create']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/bang-ke-cong-viec' title='Điểm thù lao' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.BangKeCongViec.Duyet']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/duyet-bang-ke-cong-viec' title='Duyệt điểm thù lao' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.ThuLao.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/tong-thu-lao' title='Tổng thù lao' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.ThuLao.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/thu-lao' title='Tính thù lao' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.ThuLaoCaNhan.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/tl-ca-nhan' title='Thù lao cá nhân' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.NhuanBut.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/doi-tuong-huong-nhuan-but' title='Đối tượng hưởng nhuận bút' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.NhuanBut.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/tong-nhuan-but' title='Tổng nhuận bút' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.NhuanButCaNhan.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/nb-ca-nhan' title='Nhuận bút cá nhân' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.NhuanBut.Manage']) && (
            <SidebarMenuItem to='thu-lao-nhuan-but/nhuan-but-thang' title='Tra cứu nhuận bút' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Quản lý dịch vụ quảng cáo */}
      {checkRole(currentPermissions, ['Permissions.DichVuQuangCao.Manage']) && (
        <SidebarMenuItemWithSub to='dichvuquangcao' title='Dịch vụ, quảng cáo' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen039.svg'>
          <>
            <SidebarMenuItem to='dichvuquangcao/bang-gia-dich-vu' title='Bảng giá dịch vụ' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/don-vi-quang-cao' title='Đơn vị quảng cáo' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/doi-tac-hop-dong' title='Đối tác hợp đồng sản xuất' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/don-vi-hop-dong' title='Đơn vị hợp đồng' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/hop-dong' title='Thông tin hợp đồng' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/cong-viec-hop-dong' title='Khối lượng công việc của hợp đồng' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/san-pham-hop-dong' title='Sản phẩm của hợp đồng' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/dv-quang-cao' title='Dịch vụ quảng cáo' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/khach-hang' title='Khách hàng tiềm năng' hasBullet={true} />
            <SidebarMenuItem to='dichvuquangcao/nhom-khach-hang' title='Nhóm khách hàng' hasBullet={true} />
          </>
        </SidebarMenuItemWithSub>
      )}

      {/* Đa nền tảng */}
      {checkRole(currentPermissions, ['Permissions.LichPhatSong.Manage']) && (
        <SidebarMenuItemWithSub to='danentang' title='Đa nền tảng' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen059.svg'>
          <SidebarMenuItem to='danentang/nen-tang-phat-song' title='Nền tảng phát sóng' hasBullet={true} />
          <SidebarMenuItem to='danentang/truyen-hinh' title='Phát sóng truyền hình' hasBullet={true} />
          <SidebarMenuItem to='danentang/phat-thanh' title='Phát thanh' hasBullet={true} />
          <SidebarMenuItem to='danentang/nen-tang-so' title='Nền tảng số' hasBullet={true} />
          <SidebarMenuItem to='danentang/su-co' title='Sự cố' hasBullet={true} />
          <SidebarMenuItem to='danentang/ca-truc' title='Ca trực' hasBullet={true} />
          <SidebarMenuItem to='danentang/tra-cuu' title='Tra cứu lịch phát sóng' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}

      {/* MAM */}
      {checkRole(currentPermissions, ['Permissions.PhanMemMAM.Manage']) && (
        <SidebarMenuItemWithSub to='pmmam' title='Phần mềm MAM' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen056.svg'>
          <SidebarMenuItem to='pmmam/dm-luu-tru' title='Danh mục lưu trữ' hasBullet={true} />
          <SidebarMenuItem to='pmmam/loai-tin' title='Thể loại tin, bài' hasBullet={true} />
          <SidebarMenuItem to='pmmam/tac-pham-luu-tru' title='Tác phẩm lưu trữ' hasBullet={true} />
          <SidebarMenuItem to='pmmam/tin-bai' title='Tin bài' hasBullet={true} />
          <SidebarMenuItem to='pmmam/doi-tuong-huong-thu-lao' title='Đối tượng hưởng thù lao' hasBullet={true} />
          <SidebarMenuItem to='pmmam/doi-tuong-huong-nhuan-but' title='Đối tượng hưởng nhuận bút' hasBullet={true} />
          <SidebarMenuItem to='pmmam/cong-tac-vien' title='Cộng tác viên' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}

      {/* Quản lý thiết bị */}
      {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage', 'Permissions.QuanLyThietBi.View']) && (
        <SidebarMenuItemWithSub to='quan-ly-thiet-bi' title='Quản lý thiết bị' fontIcon='bi-sticky' icon='/media/icons/duotune/finance/fin001.svg'>
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/thiet-bi' title='Thiết bị' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage', 'Permissions.QuanLyThietBi.View']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/phieu-dang-ky-xe' title='Phiếu đăng ký sử dụng xe' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/duyet-phieu-dang-ky-xe' title='Duyệt phiếu đăng ký sử dụng xe' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage', 'Permissions.QuanLyThietBi.View']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/phieu-dang-ky-phong-hop' title='Phiếu đăng ký sử dụng phòng họp' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/duyet-phieu-dang-ky-phong-hop' title='Duyệt phiếu đăng ký sử dụng phòng họp' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage', 'Permissions.QuanLyThietBi.View']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/phieu-dang-ky-truong-quay' title='Phiếu đăng ký sử dụng trường quay' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/duyet-phieu-dang-ky-truong-quay' title='Duyệt phiếu đăng ký sử dụng trường quay' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage', 'Permissions.QuanLyThietBi.View']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/phieu-dang-ky-phong-dung' title='Phiếu đăng ký sử dụng phòng dựng' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/duyet-phieu-dang-ky-phong-dung' title='Duyệt phiếu đăng ký sử dụng phòng dựng' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage', 'Permissions.QuanLyThietBi.View']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/phieu-dang-ky-camera' title='Phiếu đăng ký sử dụng Camera' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/duyet-phieu-dang-ky-camera' title='Duyệt phiếu đăng ký sử dụng Camera' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.QuanLyThietBi.Manage']) && (
            <SidebarMenuItem to='quan-ly-thiet-bi/tra-cuu' title='Tra cứu cơ sở vật chất' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Quản lý định mức */}
      {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
        <SidebarMenuItemWithSub
          to='dinhmuckinhte'
          title='Định mức kinh tế kỹ thuật'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen041.svg'
        >
          {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
            <>
              <SidebarMenuItem to='dinhmuckinhte/phat-thanh' title='Phát thanh' hasBullet={true} />
              <SidebarMenuItem to='dinhmuckinhte/truyen-hinh' title='Truyền hình' hasBullet={true} />
              <SidebarMenuItem to='dinhmuckinhte/nen-tang-so' title='Nền tảng số' hasBullet={true} />
            </>
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Lập dự toán thu chi */}
      {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
        <SidebarMenuItemWithSub to='dutoanthuchi' title='Dự toán thu chi' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen041.svg'>
          {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
            <>
              <SidebarMenuItem to='dutoanthuchi/muc-thu' title='Danh mục thu' hasBullet={true} />
              <SidebarMenuItem to='dutoanthuchi/muc-chi' title='Danh mục chi' hasBullet={true} />
              <SidebarMenuItem to='dutoanthuchi/du-toan-thu' title='Dự toán thu' hasBullet={true} />
              <SidebarMenuItem to='dutoanthuchi/du-toan-chi' title='Dự toán chi' hasBullet={true} />
            </>
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Quản lý đánh giá, xếp loại */}
      {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Manage', 'Permissions.DanhGiaXepLoai.View','Permissions.DanhGiaXepLoai.ThongQua','Permissions.DanhGiaXepLoai.Duyet']) && (
        <SidebarMenuItemWithSub to='danh-gia-xep-loai' title='Đánh giá cán bộ' fontIcon='bi-sticky' icon='/media/icons/duotune/general/gen037.svg'>
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Manage']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/bo-tieu-chi' title='Bộ tiêu chí' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Manage']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/tieu-chi' title='Tiêu chí đánh giá' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Manage']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/xep-loai' title='Xếp loại đánh giá' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Manage', 'Permissions.DanhGiaXepLoai.View']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/bang-ke-san-pham-thang' title='Bảng kê sản phẩm hàng tháng' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Manage', 'Permissions.DanhGiaXepLoai.View']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/phieu-danh-gia' title='Tự đánh giá' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.ThongQua']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/pdg-cho-nhan-xet' title='Danh sách phiếu chờ nhận xét' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Duyet']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/pdg-cho-duyet' title='Danh sách phiếu duyệt' hasBullet={true} />
          )}
          {checkRole(currentPermissions, ['Permissions.DanhGiaXepLoai.Manage']) && (
            <SidebarMenuItem to='danh-gia-xep-loai/thong-ke-hoan-thanh-nv' title='Thống kê, báo cáo mức độ hoàn thành nhiệm vụ' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Tích hợp, chia sẻ dữ liệu */}
      {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
        <SidebarMenuItemWithSub
          to='tich-hop'
          title='Tích hợp, chia sẻ dữ liệu'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/communication/com007.svg'
        >
          {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
            <>
              <SidebarMenuItemWithSub to='tich-hop' title='Chương trình, Đề tài sản xuất' hasBullet={true}>
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/chuong-trinh-de-tai/lich-su' title='Danh sách lịch sử gọi API' hasBullet={true} />
                )}
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/chuong-trinh-de-tai/mo-ta-tham-so' title='Thông tin mô tả tham số đầu vào, đầu ra' hasBullet={true} />
                )}
              </SidebarMenuItemWithSub>
            </>
          )}
          {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
            <>
              <SidebarMenuItemWithSub to='tich-hop' title='Lịch phát sóng' hasBullet={true}>
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/lich-phat-song/lich-su' title='Danh sách lịch sử gọi API' hasBullet={true} />
                )}
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/lich-phat-song/mo-ta-tham-so' title='Thông tin mô tả tham số đầu vào, đầu ra' hasBullet={true} />
                )}
              </SidebarMenuItemWithSub>
            </>
          )}
          {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
            <>
              <SidebarMenuItemWithSub to='tich-hop' title='Danh sách dịch vụ, quảng cáo' hasBullet={true}>
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/dich-vu/lich-su' title='Danh sách lịch sử gọi API' hasBullet={true} />
                )}
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/dich-vu/mo-ta-tham-so' title='Thông tin mô tả tham số đầu vào, đầu ra' hasBullet={true} />
                )}
              </SidebarMenuItemWithSub>
            </>
          )}
          {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
            <>
              <SidebarMenuItemWithSub to='tich-hop' title='Đánh giá, xếp loại mức độ hoàn thành nhiệm vụ' hasBullet={true}>
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/danh-gia-can-bo/lich-su' title='Danh sách lịch sử gọi API' hasBullet={true} />
                )}
                {checkRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
                  <SidebarMenuItem to='tich-hop/danh-gia-can-bo/mo-ta-tham-so' title='Thông tin mô tả tham số đầu vào, đầu ra' hasBullet={true} />
                )}
              </SidebarMenuItemWithSub>
            </>
          )}
        </SidebarMenuItemWithSub>
      )}

      {/* Quản trị hệ thống */}
      {checkRole(currentPermissions, ['Permissions.HeThong.Manage']) && (
        <SidebarMenuItemWithSub to='he-thong-nguoi-dung' title='Quản trị hệ thống' fontIcon='bi-sticky' icon='/media/icons/duotune/coding/cod001.svg'>
          <SidebarMenuItem to='he-thong-nguoi-dung/co-cau-to-chuc' title='Cơ cấu tổ chức' hasBullet={true} />
          <SidebarMenuItem to='he-thong-nguoi-dung/chuc-vu' title='Chức vụ' hasBullet={true} />
          <SidebarMenuItem to='he-thong-nguoi-dung/vai-tro' title='Nhóm quyền người dùng' hasBullet={true} />
          <SidebarMenuItem to='he-thong-nguoi-dung/nguoi-dung' title='Người dùng' hasBullet={true} />
          <SidebarMenuItem to='he-thong-nguoi-dung/nhat-ky-hoat-dong' title='Nhật ký hoạt động' hasBullet={true} />
          <SidebarMenuItem to='he-thong-nguoi-dung/nhat-ky-dang-nhap' title='Nhật ký đăng nhập' hasBullet={true} />
          <SidebarMenuItem to='he-thong-nguoi-dung/huong-dan-su-dung' title='Hướng dẫn sử dụng' hasBullet={true} />
          <SidebarMenuItem to='he-thong-nguoi-dung/danh-muc-cong-viec' title='Danh mục công việc' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )}

      {/* Quản lý quy trình */}
      {checkRole(currentPermissions, [
        'Permissions.DeAnViTriViecLam.Search',
        'Permissions.BangMoTaCongViecCaNhan.Search',
        'Permisisons.KeHoachCongTacThangCaNhan.Search',
        'Permissions.ThuLao.Manage',
      ]) && (
        <SidebarMenuItemWithSub to='quan-ly-quy-trinh' title='Quản lý quy trình' fontIcon='bi-sticky' icon='/media/icons/duotune/social/soc009.svg'>
          {/* Quản lý lao động */}
          {checkRole(currentPermissions, [
            'Permissions.DeAnViTriViecLam.Search',
            'Permissions.BangMoTaCongViecCaNhan.Search',
            'Permisisons.KeHoachCongTacThangCaNhan.Search',
            'Permisisons.KeHoachCongTacNamCaNhan.Search',
            'Permissions.TongHopThuNhapCoQuan.Search',
          ]) && (
            <SidebarMenuItemWithSub title='Quản lý lao động' fontIcon='bi-sticky' icon='/media/icons/duotune/social/soc009.svg'>
              {checkRole(currentPermissions, ['Permissions.DeAnViTriViecLam.Search']) && (
                <SidebarMenuItem to='quan-ly-quy-trinh/de-an-vi-tri-viec-lam' title='Đề án vị trí việc làm' hasBullet={true} />
              )}

              {checkRole(currentPermissions, ['Permissions.BangMoTaCongViecCaNhan.Search']) && (
                <SidebarMenuItem to='quan-ly-quy-trinh/bang-mo-ta-cong-viec-ca-nhan' title='Bảng mô tả công việc' hasBullet={true} />
              )}

              {checkRole(currentPermissions, ['Permissions.KeHoachCongTacThangCaNhan.Search']) && (
                <SidebarMenuItem to='quan-ly-quy-trinh/ke-hoach-cong-tac-thang-ca-nhan' title='Kế hoạch công tác tháng cá nhân' hasBullet={true} />
              )}

              {checkRole(currentPermissions, ['Permissions.KeHoachCongTacNamCaNhan.Search']) && (
                <SidebarMenuItem to='quan-ly-quy-trinh/ke-hoach-cong-tac-nam-ca-nhan' title='Kế hoạch công tác năm cá nhân' hasBullet={true} />
              )}

              {checkRole(currentPermissions, ['Permissions.TongHopThuNhapCoQuan.Search', 'Permissions.ThuLao.Manage']) && (
                <SidebarMenuItem to='quan-ly-quy-trinh/tong-hop-thu-nhap-co-quan' title='Tổng hợp thu nhập cơ quan' hasBullet={true} />
              )}
            </SidebarMenuItemWithSub>
          )}
        </SidebarMenuItemWithSub>
      )}

      {checkRole(currentPermissions, ['Permissions.DocGia.Manage']) && (
        <SidebarMenuItemWithSub
          to='khai-thac-tai-lieu'
          title='Khai thác tài liệu'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/finance/fin006.svg'
        >
          <SidebarMenuItem to='khai-thac-tai-lieu/ds-doc-gia' title='Danh sách độc giả' hasBullet={true} />
          {/*  <SidebarMenuItem to='/khai-thac-tai-lieu/so-dang-ky-doc-gia' title='Sổ đăng ký độc giả' hasBullet={true} /> */}
          <SidebarMenuItem to='khai-thac-tai-lieu/pdk-cho-giai-quyet' title='Phiếu yêu cầu chờ giải quyết' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/pdk-da-tu-choi' title='Phiếu yêu cầu đã từ chối' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/pdk-da-giai-quyet' title='Phiếu yêu cầu đã giải quyết' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/pyc-doc-tai-lieu' title='Phiếu yêu cầu đọc tài liệu' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/pyc-sao-tai-lieu' title='Phiếu yêu cầu sao tài liệu' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/pyc-chungthuc-tai-lieu' title='Phiếu yêu cầu chứng thực tài liệu' hasBullet={true} />

          <SidebarMenuItem to='khai-thac-tai-lieu/tai-lieu-goc-dang-muon' title='Tài liệu đang được mượn' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/tai-lieu-goc-qua-han' title='Tài liệu quá hạn trả' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/tai-lieu-goc-da-tra' title='Tài liệu đã được trả' hasBullet={true} />
          {/* <SidebarMenuItem to='khai-thac-tai-lieu/tai-lieu-dientu-dang-muon' title='Tài liệu điện tử đang được mượn' hasBullet={true} />
          <SidebarMenuItem to='khai-thac-tai-lieu/tai-lieu-dientu-da-tra' title='Tài liệu điện tử đã được trả' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>
      )}
    </>
  );
};

export {SidebarMenuMain};
