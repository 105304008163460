import {Link} from 'react-router-dom';
import {toAbsoluteUrl} from '../../../helpers';
import {useLayout} from '../../core';

const SidebarLogo = () => {
  const {config} = useLayout();

  return (
    <div className='app-sidebar-logo px-3' id='kt_app_sidebar_logo'>
      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' ? (
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default-dark.svg')} className='h-25px app-sidebar-logo-default' />
        ) : (
          <>
            {/* <img
              alt='Logo'
              //src={toAbsoluteUrl('/media/logos/default.svg')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            /> */}
            {/* <span>
              <h3 style={{color: '#fff'}}>Quản lý đài truyền hình</h3>
            </span> */}
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/default-dark.svg')} className='h-40px app-sidebar-logo-default theme-light-show' />
          </>
        )}
      </Link>
    </div>
  );
};

export {SidebarLogo};
