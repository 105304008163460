import {lazy, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';

import {useAuth} from 'src/app/modules/auth';
import {CheckRole} from 'src/utils/utils';

const PrivateRoutes = () => {
  const DanhMucPage = lazy(() => import('../pages/DanhMucPage'));
  const DanhGiaXepLoaiPage = lazy(() => import('../pages/DanhGiaXepLoaiPage'));
  const HeThongPage = lazy(() => import('../pages/HeThongPage'));
  const ChuongTrinhDeTaiPage = lazy(() => import('../pages/ChuongTrinhDeTaiPage'));
  const ThuLaoNhuanButPage = lazy(() => import('../pages/ThuLaoNhuanButPage'));
  const DichVuQuangCaoPage = lazy(() => import('../pages/DichVuQuangCaoPage'));
  const ThietBiPage = lazy(() => import('../pages/ThietBiPage'));
  const HeThongNguoiDungPage = lazy(() => import('../pages/HeThongNguoiDungPage'));
  const PMMAMPage = lazy(() => import('../pages/PMMAMPage'));
  const DaNenTangPage = lazy(() => import('../pages/DaNenTangPage'));
  const DuToanThuChiPage = lazy(() => import('../pages/DuToanThuChiPage'));
  const TichHopChiaSePage = lazy(() => import('../pages/TichHopChiaSePage'));
  const QuanLyQuyTrinhPage = lazy(() => import('../pages/QuanLyQuyTrinhPage'));
  const DinhMucKinhTePage = lazy(() => import('../pages/DinhMucKinhTePage'));

  const {currentPermissions} = useAuth();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='*' element={<Navigate to='/manage/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='he-thong/*'
          element={
            <SuspensedView>
              <HeThongPage />
            </SuspensedView>
          }
        />

        <Route
          path='chuong-trinh-de-tai/*'
          element={
            <SuspensedView>
              <ChuongTrinhDeTaiPage />
            </SuspensedView>
          }
        />

        <Route
          path='danh-muc/*'
          element={
            <SuspensedView>
              <DanhMucPage />
            </SuspensedView>
          }
        />

        <Route
          path='danh-gia-xep-loai/*'
          element={
            <SuspensedView>
              <DanhGiaXepLoaiPage />
            </SuspensedView>
          }
        />

        <Route
          path='dinhmuckinhte/*'
          element={
            <SuspensedView>
              <DinhMucKinhTePage />
            </SuspensedView>
          }
        />

        {CheckRole(currentPermissions, ['Permissions.DichVuQuangCao.Manage']) && (
          <Route
            path='dichvuquangcao/*'
            element={
              <SuspensedView>
                <DichVuQuangCaoPage />
              </SuspensedView>
            }
          />
        )}

        {CheckRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
          <Route
            path='pmmam/*'
            element={
              <SuspensedView>
                <PMMAMPage />
              </SuspensedView>
            }
          />
        )}

        {CheckRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
          <Route
            path='danentang/*'
            element={
              <SuspensedView>
                <DaNenTangPage />
              </SuspensedView>
            }
          />
        )}

        {CheckRole(currentPermissions, ['Permissions.DanhMuc.Manage']) && (
          <Route
            path='dutoanthuchi/*'
            element={
              <SuspensedView>
                <DuToanThuChiPage />
              </SuspensedView>
            }
          />
        )}

        <Route
          path='dich-vu-quang-cao/*'
          element={
            <SuspensedView>
              <DichVuQuangCaoPage />
            </SuspensedView>
          }
        />

        <Route
          path='quan-ly-thiet-bi/*'
          element={
            <SuspensedView>
              <ThietBiPage />
            </SuspensedView>
          }
        />
        <Route
          path='thu-lao-nhuan-but/*'
          element={
            <SuspensedView>
              <ThuLaoNhuanButPage />
            </SuspensedView>
          }
        />

        <Route
          path='thiet-bi/*'
          element={
            <SuspensedView>
              <ThietBiPage />
            </SuspensedView>
          }
        />

        <Route
          path='he-thong-nguoi-dung/*'
          element={
            <SuspensedView>
              <HeThongNguoiDungPage />
            </SuspensedView>
          }
        />

        <Route
          path='quan-ly-quy-trinh/*'
          element={
            <SuspensedView>
              <QuanLyQuyTrinhPage />
            </SuspensedView>
          }
        />

        <Route
          path='tich-hop/*'
          element={
            <SuspensedView>
              <TichHopChiaSePage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
