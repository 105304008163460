import {PaginationFilter, LoginLog} from '@app/models';
import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/loginlogs`;

const searchAsync = async (paginationFilter: PaginationFilter) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${baseUrl}/search`,
      data: JSON.stringify(paginationFilter),
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

const addAsync = async (model: LoginLog) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${baseUrl}`,
      data: JSON.stringify(model),
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

const updateAsync = async (id: string, model: LoginLog) => {
  try {
    const res = await axios({
      method: 'PUT',
      url: `${baseUrl}/${id}`,
      data: JSON.stringify(model),
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

const getByIdAsync = async (id: string) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${baseUrl}/${id}`,
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

const removeAsync = async (id: string) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `${baseUrl}/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

export const loginLogApi = {
  searchAsync,
  addAsync,
  updateAsync,
  getByIdAsync,
  removeAsync,
};
