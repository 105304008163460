import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import {modalSlice} from './modal/Slice';
import {globalSlice} from './global/Slice';
import {theLoaiSlice} from './the-loai/Slice';
import {chuongTrinhDeTaiSlice} from './chuong-trinh-de-tai/Slice';

export const rootReducer = combineReducers({
  modal: modalSlice.reducer,
  global: globalSlice.reducer,
  theLoai: theLoaiSlice.reducer,
  chuongTrinhDeTai: chuongTrinhDeTaiSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([]);
}
