import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/dashboard`;

const getNhacViecDeTai = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `${baseUrl}/NhacViecDeTai`,
      timeout: 15000,
    });

    return res?.data;
  } catch (error: any) {
    console.error(error?.response);
    return null;
  }
};

export const dashboardApi = {
  getNhacViecDeTai,
};
